@import 'variables';
@import './node_modules/@systems/base/assets/style/main.scss';


@font-face {
  font-family: 'FontAwesome';
  //in url add your folder path of FontAwsome Fonts
  src: url('/portal/assets/webfonts/fa-light-300.ttf') format('truetype');
}


nav {
  background: $primary;

  a.navbar-logo {
    width: $sibebar-width;
    min-width: $sibebar-collapsed-width;
    $logo-filename: map-get($logo, "filename");
    background-image: url("/portal/assets/media/#{$logo-filename}");
    background-position: center center;
    background-size: map-get($logo, "width") map-get($logo, "height");
    background-repeat: no-repeat;
    height: 100%;
    background-color: $navbar-brand-background-color;

    @include media-breakpoint-down(lg) {
      width: $sibebar-collapsed-width;
      $logo-filename: map-get($logo-small, "filename");
      background-image: url("/portal/assets/media/#{$logo-filename}");
      background-size: map-get($logo-small, "width") map-get($logo-small, "height");
    }
  }
}

.brand {
  width: 100%;
  $logo-filename: map-get($logoLogin, "filename");
  background-image: url("/portal/assets/media/#{$logo-filename}");
  background-position: center center;
  background-repeat: no-repeat;
  height: map-get($logoLogin, "height")*2+50px;
  margin-bottom: 30px;
}

.fullscreen {
  background-color: $background-color;
  background: $background;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

}

.login-container {
  margin-top: 50px;
}


@include media-breakpoint-down(md) {
  div.row>div {
    margin-bottom: $space / 2;
  }
}

#passwordReset {
  color: invert($background-color) !important;
}

#archiveDashboard,
#postboxDashboard {

  a:hover {
    text-decoration: none;
  }

  .nav-item {
    border: none;
    border-left: 7px solid transparent;

    &:hover {
      border: none;
      border-left: 7px solid $primary;
      background-color: rgba(211, 211, 211, 0.5);
    }

    &>.nav-link {
      color: $primary;
    }
  }

  .navbar-toggler {
    color: $black;

    &:hover {
      opacity: 0.7;
    }
  }

  .nav-link>.fa,
  .nav-link>.fal {
    vertical-align: middle;
    width: 24px;
    text-align: center;
  }
}

.table {
  td.fit,
  th.fit {
    white-space: nowrap;
    width: 1%;
  }

  td.fitspecial,
  th.fitspecial {
    white-space: initial;
    min-width: 112px
  }
}

@include media-breakpoint-down(sm) {
  .hideExtraSmall {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .hideSmall {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .hideMid {
    display: none;
  }
}

@include media-breakpoint-down(sm) {

  div.navbar-collapse,
  div.dropdown-menu {
    background: $dropdown-bg;
    box-shadow: $box-shadow;
    border: $dropdown-border-width solid $dropdown-border-color;
    border-left: none;
    border-right: none;
    left: 0;
    margin: 0;
    padding: $spacer 0;
    position: absolute;
    right: 0;
    top: $navbar-height;
    width: 100%;

    >ul.navbar-nav>li.nav-item,
    >.dropdown-item {
      margin: 0;
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    }

    >ul.navbar-nav>li.nav-item>.nav-link {
      padding: 0;
    }
  }
}

.badge-yellow {
  background-color: rgb(255, 174, 0);
  color: $dark  !important;
}

.bg-warning {
  color: $dark  !important;
}

.badge-yellow {
  color: $white  !important;
}

.badge-light {
  background-color: rgb(112, 113, 115) !important;
  color: $white  !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
  white-space: normal;
}


*.clickable {
  cursor: pointer;
}

.clickable icon {
  float: left;
}

.sidebar-hidden {
  margin-left: 65px;
}

a.list-commands {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    color: inherit;

    & .fal {
      font-weight: 800;
    }
  }
}

@media print {

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @page {
    size: A4;
  }
  
  .d-print-full {
    margin-left: 0px !important;
  }

  @-moz-document url-prefix() {

    html,
    body {
      width: 100%;
      height: 297mm;
      font-size: 85% !important;
    }

    @page {
      size: A4;
    }


    div.card,
    app-dettaglio-linee .row,
    app-dettaglio-linee #dettaglio-linee .accordion-body .row {
      display: block !important;
    }

    body,
    div,
    h1,
    h2,
    h3,
    p,
    th,
    td,
    a {
      color: #000 !important;
      background-color: #fff !important;
      margin: 0 !important;
    }

    .accordion-button {
      background-color: #fff !important
    }
  }

  .accordion-button::after {
    content: none;
  }
}

#not-found {
  // https://pixabay.com/en/head-stuck-in-the-sand-3510206/
  background-image: url('/portal/assets/img/404.jpg');
  background-position: top center;
  background-size: cover;
  display: block;
  min-height: calc(100vh - #{$navbar-height});
  padding-top: $spacer * 5;

  .container {
    background-color: rgba($light, 0.5);
    padding: $spacer;
  }
}


// make viewer/editor smaller in sizing
[ngbPanelToggle],
.accordion-body {
  padding: $spacer * .5 $spacer  !important;
}

td {
  .input-container {
    margin: 0 !important;
  }
}

footer {
  color: invert($background-color);
  font-size: $font-size-sm;
  text-align: center;
  width: 100%;

  a,
  a:hover {
    text-decoration: none;
    color: invert($background-color);

    &:hover {
      text-decoration: underline;
    }
  }
}

.number-link {
  color: #003f8a !important;

  &:hover {
    text-decoration: underline;
  }
}

#dropdownProfile {
  color: white;
}

nav {
  &.navbar {
    .nav-item {
      .nav-link {
        &>.nav-item-label {
          @media (max-width: 1500px) {
            display: none;
          }
        }

        &>.nav-link-label {
          @media (max-width: 1500px) {
            display: none;
          }
        }
      }

    }
  }
}


#extendedDocSearch {
  .row.w-100.m-0 {
    @include media-breakpoint-down(xl) {
      .col-md-auto {
        width: 25%;
      }
    }
  }
}

@-moz-document url-prefix() {
  // workaround to make textareas as big as in chrome
  // https://stackoverflow.com/a/22700700/19843230
  #textareaControl {
    overflow-x: hidden;
  }
}

.card-commands{
  float: right; 
}


.sidebar-content {
  padding: 1rem !important;
}

.menubar-bottom-row{
  background-color: #d0d0ce !important;
color: black !important;
padding-top: 20px !important;
padding-bottom: 20px !important;    
margin-left: 0px !important;
margin-right: 0px !important;
padding-right: 9px !important;
padding-left: 9px !important;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 576px)
{
  .navbar-collapse {
    background-color: $primary !important;
  }
}


.btn-field.dropdown-toggle {
  border-color: var(--bs-secondary);
}