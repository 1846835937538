@import "./node_modules/@systems/base/assets/style/systems-variables.scss";
@import 'variables';
@import './src/assets/style/base/main';
@import './node_modules/@systems/base/assets/style/systems-style.scss';

.btn-field {
    --bs-btn-bg: #005670;
    --bs-btn-color: white;
    --bs-btn-border-color: #005670;
    --bs-btn-active-color: white;
    --bs-btn-active-bg: #003a4d;
    --bs-btn-active-border-color: #002f3f;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: #003f53;
    --bs-btn-hover-border-color: #00394a;

    &.dropdown-toggle {
        border-color: #005F7A;
    }
}