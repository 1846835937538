@import './node_modules/@systems/base/assets/style/systems-variables.scss';

// Application variables  ------------------------
$navbar-brand-background-color: $blue-dark;
$background: white;
$info: #007398;
$logo: ( "filename": 'logo-primary-white.svg', "height": 40px, "width": 170px);
$logo-small: ( "filename": 'logo-primary-white-small.svg', "height": 40px, "width": 40px);
$logoLogin: ( "filename": 'logo_big.png', "height": 35px, "width": 120px);

// Application variables  ------------------------
$transitionDuration: 0.15s;
$animationDuration: 2s;
// Bootstrap specific variables ------------------
$border-radius: 0.25rem !default;
$border-radius-lg: 0.25rem !default;
$border-radius-sm: 0.25rem !default;
$navbar-height: 50px;
$footer-height: 50px;
//Button Main variables ------------------
$navbar-color: white;